import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import AppRoutes from "./routes/AppRoutes.js";
import "./assets/css/bootstrap.min.css";
import "./assets/css/swiper-bundle.min.css";
import "./assets/css/main.css";

const App = () => (
  <Router>
    <Header />
    <AppRoutes />
    <Footer />
  </Router>
);

export default App;
