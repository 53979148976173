import React from "react";
import Header from "../components/common/CommonHeader.js";
import SearchHistoryAndProperties from "../components/RecentSearch/SearchHistoryAndProperties .js";

const RecentlySpotted = () => (
  <div>
    <Header showMapBanner={true} />
    <SearchHistoryAndProperties />
  </div>
);

export default RecentlySpotted;
