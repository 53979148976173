import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import footerData from "../data/footerData.json"; // Adjust the path as needed
import imageLoader from "../utils/imageLoader"; // Import the imageLoader function

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col lg={3} md={6} sm={6}>
            <img
              src={imageLoader(footerData.logo)}
              className="img-fluid"
              alt="footer-logo"
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <div className="footer-inn">
              <h4>SELL A HOME</h4>
              <ul className="footer-details">
                {footerData.sellAHome.map((item, index) => (
                  <li key={index}>
                    <a href={item.link}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-inn">
              <h4>BUY A HOME</h4>
              <ul className="footer-details">
                {footerData.buyAHome.map((item, index) => (
                  <li key={index}>
                    <a href={item.link}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6}>
            <div className="footer-inn">
              <h4>BUY, RENT AND SELL</h4>
              <ul className="footer-details">
                {footerData.buyRentAndSell.map((item, index) => (
                  <li key={index}>
                    <a href={item.link}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-inn">
              <h4>TERMS & PRIVACY</h4>
              <ul className="footer-details">
                {footerData.termsPrivacy.map((item, index) => (
                  <li key={index}>
                    <a href={item.link}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6}>
            <div className="footer-inn">
              <h4>ABOUT</h4>
              <ul className="footer-details">
                {footerData.about.map((item, index) => (
                  <li key={index}>
                    <a href={item.link}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-inn pb-xs-0">
              <h4>RESOURCES</h4>
              <ul className="footer-details">
                {footerData.resources.map((item, index) => (
                  <li key={index}>
                    <a href={item.link}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
