// Utility function to load images dynamically
const imageLoader = (imageName) => {
  try {
    console.log("imageName", imageName);
    return require(`../assets/images/${imageName}`);
  } catch (error) {
    console.error(`Error loading image: ${imageName}`, error);
    return null; // Return null if the image fails to load
  }
};

export default imageLoader;
