import React from "react";
import imageLoader from "../../utils/imageLoader";
import SearchData from "../../data/searchData.json";
const SearchHistory = () => {
  return (
    <section className="home-plots custom-spacing">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <div className="heading">
                <h2>Search History</h2>
                <ul>
                  {SearchData.searchHistory.map((item, index) => (
                    <li key={index}>
                      {item}
                      <img src={imageLoader("icons/close.svg")} alt="close" />
                    </li>
                  ))}
                </ul>
                <div className="link-text">
                  <a href="#">Load More</a>
                  <a href="#">Clear All</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <div className="heading">
                <h2>Recently Spotted Properties</h2>
                <p>Tracking Your Latest Real Estate Interests</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-gap">
          {SearchData.properties.map((property) => (
            <div key={property.id} className="col-lg-4 col-md-6">
              <div className="plots-card fav">
                <div className="image">
                  <img
                    src={imageLoader(property.image)}
                    className="img-fluid"
                    alt={property.title}
                  />
                </div>
                <div className="context">
                  <div className="badge">
                    <img src={imageLoader("icons/stars.svg")} alt="popular" />
                    <span>POPULAR</span>
                  </div>
                  <div className="price-likes">
                    <div className="price">
                      <h4>
                        <span className="cost">{property.price}</span>
                        <span className="month">/month</span>
                      </h4>
                    </div>
                    <div className="likes">
                      <img
                        src={imageLoader("icons/fav-hover.svg")}
                        className="img-fluid"
                        alt="favorite"
                      />
                    </div>
                  </div>
                  <h3>{property.title}</h3>
                  <p>{property.address}</p>
                  <div className="numbers-flex">
                    <ul>
                      <li>
                        <img src={imageLoader("icons/bed.svg")} alt="bed" />
                        {property.beds} Beds
                      </li>
                      <li>
                        <img src={imageLoader("icons/tub.svg")} alt="bath" />{" "}
                        {property.baths} Bathrooms
                      </li>
                      <li>
                        <img src={imageLoader("icons/square.svg")} alt="size" />
                        {property.size}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SearchHistory;
