import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import imageLoader from "../../utils/imageLoader"; // Import the imageLoader function
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import the testimonials data from the JSON file
import testimonialsData from "../../data/testimonials.json"; // Adjust the path if necessary

const Testimonials = () => {
  return (
    <section className="home-testimonial custom-spacing">
      <Container>
        <Row>
          <Col lg="12">
            <div className="section-title text-center">
              <h2>Testimonials</h2>
              <p>
                See what our property managers, landlords, and <br />
                tenants have to say
              </p>
            </div>
            <Swiper
              modules={[Pagination, Navigation]}
              spaceBetween={30}
              pagination={{ clickable: true }}
              className="mySwiper"
            >
              {testimonialsData.map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <div className="testimonial-content text-center">
                    <p>{testimonial.quote}</p>
                    <div className="quote-icon">
                      <i className="fas fa-quote-left"></i>
                    </div>
                    <div className="name-details">
                      <ul>
                        <li>
                          <b>{testimonial.name},</b> {testimonial.role}
                        </li>
                      </ul>
                    </div>
                    <img
                      src={imageLoader(testimonial.avatar)}
                      className="img-fluid"
                      alt={testimonial.name}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;
