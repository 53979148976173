// propertyData.js

const properties = [
  {
    id: 1,
    image: "plots/plot-1.jpg",
    price: "$2,095",
    month: "/month",
    title: "Palm Harbor",
    address: "2699 Green Valley, Highland Lake, FL",
    beds: 3,
    bathrooms: 2,
    area: "5x7 m²",
    badge: "POPULAR",
  },
  {
    id: 2,
    image: "plots/plot-2.jpg",
    price: "$2,700",
    month: "/month",
    title: "Beverly Springfield",
    address: "2821 Lake Sevilla, Palm Harbor, TX",
    beds: 4,
    bathrooms: 2,
    area: "6x7.5 m²",
    badge: "POPULAR",
  },
  {
    id: 3,
    image: "plots/plot-3.jpg",
    price: "$4,550",
    month: "/month",
    title: "Faulkner Ave",
    address: "909 Woodland St, Michigan, IN",
    beds: 4,
    bathrooms: 3,
    area: "8x10 m²",
    badge: "POPULAR",
  },
  {
    id: 4,
    image: "plots/plot-4.jpg",
    price: "$2,400",
    month: "/month",
    title: "St. Crystal",
    address: "210 US Highway, Highland Lake, FL",
    beds: 4,
    bathrooms: 2,
    area: "6x8 m²",
    badge: "POPULAR",
  },
  {
    id: 5,
    image: "plots/plot-5.jpg",
    price: "$1,500",
    month: "/month",
    title: "Cove Red",
    address: "243 Curlew Road, Palm Harbor, TX",
    beds: 2,
    bathrooms: 1,
    area: "5x7.5 m²",
    badge: "POPULAR",
  },
  {
    id: 6,
    image: "plots/plot-6.jpg",
    price: "$1,600",
    month: "/month",
    title: "Tarpon Bay",
    address: "103 Lake Shores, Michigan, IN",
    beds: 3,
    bathrooms: 1,
    area: "5x7 m²",
    badge: "POPULAR",
  },
];

export default properties;
