import React from "react";
import properties from "../../data/properties.json"; // Adjust the path as necessary
import imageLoader from "../../utils/imageLoader"; // Import the imageLoader function

const HomeBanner = () => {
  return (
    <section className="home-banner custom-spacing">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="banner-content-left">
              <h3>Homes at Your Fingertips.</h3>
              <h2>
                Easily Search,
                <br />
                Happily Settle.
              </h2>
              <div className="form-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search places in map with AI"
                />
              </div>
              <div className="renters-properties">
                <div className="counts">
                  <h4>50k+</h4>
                  <span>renters</span>
                </div>
                <div className="counts">
                  <h4>10k+</h4>
                  <span>properties</span>
                </div>
              </div>
              <p>Redefine your buying and selling experience!</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-content-right">
              {properties.map((property, index) => (
                <div
                  className={`banner-card ${index === 1 ? "card-small" : ""}`}
                  key={index}
                >
                  <div className="card-inn">
                    <div className="image">
                      <img
                        src={imageLoader(property.image)} // Use the imageLoader function here
                        className="img-fluid"
                        alt={property.title}
                      />
                    </div>
                    <div className="context">
                      <h4>{property.price}</h4>
                      <h3>{property.title}</h3>
                      <p>{property.address}</p>
                      <div className="numbers-flex">
                        <ul>
                          <li>
                            <img
                              src={imageLoader("icons/car.svg")}
                              alt="Cars"
                            />
                            {property.details.cars}
                          </li>
                          <li>
                            <img
                              src={imageLoader("icons/tub.svg")}
                              alt="Tubs"
                            />{" "}
                            {property.details.tubs}
                          </li>
                          <li>
                            <img
                              src={imageLoader("icons/square.svg")}
                              alt="Size"
                            />
                            {property.details.size}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
