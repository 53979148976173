import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom"; // Import NavLink from React Router
import { Form, Button, Modal } from "react-bootstrap";
import logo from "../assets/images/logo.png"; // Import the logo image
import SignIn from "../components/Auth/SignIn";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Register from "../components/Auth/Register";
const HeaderComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const navItems = [
    { name: "Home", path: "/" },
    { name: "Location", path: "/location" },
    { name: "Favorite", path: "/favorite" },
    { name: "Recent Search", path: "/recently-spotted" },
    { name: "Contact Us", path: "/contact-us" },
  ];

  const handleOpen = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setShowForgotPassword(false); // Reset forgot password state
    setShowRegister(false); // Reset register state
  };

  const handleSignIn = (credentials) => {
    console.log("Signing in with:", credentials);
    handleClose(); // Close modal after sign in
  };

  const handleForgotPassword = (email) => {
    console.log("Forgot password for:", email);
    handleClose(); // Close modal after submitting
  };

  const handleRegister = (credentials) => {
    console.log("Registering with:", credentials);
    handleClose(); // Close modal after registering
  };

  const toggleForgotPassword = () => {
    setShowForgotPassword((prev) => !prev);
    setShowRegister(false); // Reset register state when toggling
  };

  const toggleRegister = () => {
    setShowRegister((prev) => !prev);
    setShowForgotPassword(false); // Reset forgot password state when toggling
  };
  return (
    <header>
      <Navbar expand="lg" className="navbar">
        <Container fluid>
          <Navbar.Brand href="#">
            <img src={logo} className="img-fluid" alt="Brand Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarNavDropdown" />
          <Navbar.Collapse
            id="navbarNavDropdown"
            className="justify-content-center"
          >
            <Nav className="navbar-nav">
              {navItems.map((item, index) => (
                <NavLink
                  key={index}
                  to={item.path}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  } // Set active class
                >
                  {item.name}
                </NavLink>
              ))}
            </Nav>
          </Navbar.Collapse>
          <div className="login-signup-btn">
            <ul className="d-flex list-unstyled">
              <li className="me-3">
                <a href="#" className="login-btn" onClick={handleOpen}>
                  Login
                </a>
              </li>
              <li>
                <a href="#" className="signup-btn">
                  Sign Up
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </Navbar>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body>
          {showForgotPassword ? (
            <ForgotPassword
              onForgotPassword={handleForgotPassword}
              toggleForgotPassword={toggleRegister}
            />
          ) : showRegister ? (
            <Register
              onRegister={handleRegister}
              toggleSignIn={toggleRegister}
            />
          ) : (
            <SignIn
              onSignIn={handleSignIn}
              toggleForgotPassword={toggleForgotPassword}
              toggleRegister={toggleRegister}
            />
          )}
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default HeaderComponent;
