import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "react-bootstrap";
import imageLoader from "../../utils/imageLoader";
import homeAboutData from "../../data/aboutData.json";

const HomeAbout = () => {
  const [activeTab, setActiveTab] = useState("tenants");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <section className="home-about custom-spacing">
      <Container>
        <Row>
          <Col lg="6">
            <div className="about-inn-left">
              <div className="tour">
                <div className="content-inn">
                  <div className="icon">
                    <img
                      src={imageLoader(`icons/${homeAboutData.tour.icon}`)}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="context">
                    <h5>{homeAboutData.tour.title}</h5>
                    <p>{homeAboutData.tour.description}</p>
                  </div>
                </div>
              </div>
              <div className="image">
                {homeAboutData.aboutImages.map((image, index) => (
                  <img
                    key={index}
                    src={imageLoader(image)}
                    className={`img-fluid image-${index + 1}`}
                    alt=""
                  />
                ))}
              </div>
              <div className="deal">
                <div className="content-inn">
                  <div className="context">
                    <h5>{homeAboutData.deal.title}</h5>
                    <p>{homeAboutData.deal.description}</p>
                  </div>
                  <div className="icon">
                    <img
                      src={imageLoader(`icons/${homeAboutData.deal.icon}`)}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6">
            <div className="about-inn-right">
              <Nav tabs>
                {Object.keys(homeAboutData.tabs).map((tabKey) => (
                  <NavItem key={tabKey} class="nav-pills mb-3">
                    <NavLink
                      className={activeTab === tabKey ? "active" : ""}
                      onClick={() => toggleTab(tabKey)}
                    >
                      {tabKey === "tenants" ? "For tenants" : "For landlords"}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeTab}>
                {Object.entries(homeAboutData.tabs).map(([tabKey, tabData]) => (
                  <TabPane
                    key={tabKey}
                    tabId={tabKey}
                    className={activeTab === tabKey ? "active show" : ""}
                  >
                    <h2>{tabData.title}</h2>
                    {tabData.content.map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                    <a href="#">
                      {tabData.linkText}{" "}
                      <img src={imageLoader(tabData.linkIcon)} alt="" />
                    </a>
                  </TabPane>
                ))}
              </TabContent>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeAbout;
