import React from "react";
import imageLoader from "../../utils/imageLoader";
const SearchLocation = () => {
  return (
    <section className="search-location">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="search-location-holder">
              <div className="search-location-inner">
                <div className="search-location-header">
                  <div className="form-group">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search places in map with AI"
                    />
                  </div>
                </div>
                <div className="search-location-list">
                  <div className="search-list-header">
                    <div class="plots-card">Properties in Mount Pleasant</div>
                  </div>
                  <div className="search-list-content">
                    <PropertyCard
                      title="Palm Harbor"
                      address="2699 Green Valley,"
                      price="$2,095"
                      likes={imageLoader("icons/heart.svg")}
                      imgSrc={imageLoader("property-list/prop-1.jpeg")}
                      details={{ beds: 3, baths: 2, size: "5x7 m²" }}
                      badge="POPULAR"
                    />
                  </div>
                  <div className="search-list-content">
                    <PropertyCard
                      title="Bevrly Springfield"
                      address="2699 Green Valley,"
                      price="$481,773"
                      likes={imageLoader("icons/heart.svg")}
                      imgSrc={imageLoader("property-list/prop-2.jpeg")}
                      details={{ beds: 4, baths: 2, size: "6x7.5 m²" }}
                    />
                  </div>
                  <div className="search-list-content">
                    <PropertyCard
                      title="Faulkner Ave"
                      address="2699 Green Valley,"
                      price="$4,550"
                      likes={imageLoader("icons/heart.svg")}
                      imgSrc={imageLoader("property-list/prop-3.jpeg")}
                      details={{ beds: 3, baths: 2, size: "5x7 m²" }}
                    />
                  </div>
                  <div className="search-list-content">
                    <PropertyCard
                      title="Tarpon Bay"
                      address="2699 Green Valley,"
                      price="$1,600"
                      likes={imageLoader("icons/heart.svg")}
                      imgSrc={imageLoader("property-list/prop-4.jpeg")}
                      details={{ beds: 3, baths: 2, size: "5x7 m²" }}
                    />
                  </div>
                  <div className="search-list-content">
                    <PropertyCard
                      title="Cove Red"
                      address="2699 Green Valley,"
                      price="$1,670"
                      likes={imageLoader("icons/heart.svg")}
                      imgSrc={imageLoader("property-list/prop-5.jpeg")}
                      details={{ beds: 2, baths: 1, size: "5x7 m²" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// PropertyCard Component
const PropertyCard = ({
  title,
  address,
  price,
  likes,
  imgSrc,
  details,
  badge,
}) => {
  return (
    <div className="plots-card">
      {badge && (
        <div className="badge">
          <img src="assets/images/icons/stars.svg" alt="" />
          <span>{badge}</span>
        </div>
      )}
      <div class="plots-card-holder">
        <div
          className="image"
          style={{ backgroundImage: `url(${imgSrc})` }}
        ></div>
        <div className="context">
          <div className="property-content">
            <h3>{title}</h3>
            <p>{address}</p>
            <div className="price-likes">
              <div className="price">
                <h4>
                  <span className="cost">{price}</span>
                  <span className="month">/month</span>
                </h4>
              </div>
              <div className="likes">
                <div className="likes-img">
                  <img src={likes} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="numbers-flex">
            <ul>
              <li>
                <img src={imageLoader("icons/bed.svg")} alt="" />
                {details.beds}
              </li>
              <li>
                <img src={imageLoader("icons/tub.svg")} alt="" />
                {details.baths}
              </li>
              <li>
                <img src={imageLoader("icons/square.svg")} alt="" />
                {details.size}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchLocation;
