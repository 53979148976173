import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import LocationPage from "../pages/LocationPage";
import FavoritePage from "../pages/FavoritePage";
import RecentlySpotted from "../pages/RecentlySpotted";
import Contactus from "../pages/Contactus";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/location" element={<LocationPage />} />
      <Route path="/favorite" element={<FavoritePage />} />
      <Route path="/recently-spotted" element={<RecentlySpotted />} />
      <Route path="/contact-us" element={<Contactus />} />
    </Routes>
  );
};

export default AppRoutes;
