import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/common/CommonHeader.js";
import HomeNewsletter from "../components/Home/HomeNewsletter.js";
const ContactForm = () => {
  return (
    <>
      <Header showMapBanner={true} />
      <section className="contact-section custom-spacing">
        <Container>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <div className="heading">
                  <h2>Get In Touch</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 form-section">
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Your Email"
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Your Message"
                    required
                  ></textarea>
                </div>
                <div className="contact-submit">
                  <button type="submit" className="btn btn-primary w-100">
                    {" "}
                    {/* Full width button */}
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Container>
      </section>
      <HomeNewsletter />
    </>
  );
};

export default ContactForm;
