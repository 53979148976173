import React from "react";
import Header from "../components/common/CommonHeader.js";
import FavPlots from "../components/Favorite/FavPlots.js";

const FavoritePage = () => (
  <div>
    <Header showMapBanner={true} />
    <FavPlots />
  </div>
);

export default FavoritePage;
