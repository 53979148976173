import React from "react";
import HomeBanner from "../components/Home/HomeBanner.js";
import HomePlots from "../components/Home/HomePlots.js";
import HomeAbout from "../components/Home/HomeAbout.js";
import HomeTestimonials from "../components/Home/HomeTestimonials.js";
import HomeNewsletter from "../components/Home/HomeNewsletter.js";
const HomePage = () => (
  <div>
    <HomeBanner />
    <HomePlots />
    <HomeAbout />
    <HomeTestimonials />
    <HomeNewsletter />
  </div>
);

export default HomePage;
