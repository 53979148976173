import React from "react";
import properties from "../../data/propertyData"; // Adjust the path as necessary
import imageLoader from "../../utils/imageLoader"; // Import the imageLoader function

const HomePlots = () => {
  return (
    <section className="home-plots custom-spacing">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <div className="heading">
                <h2>
                  <img
                    src={imageLoader("icons/star.svg")} // Use the common image import function
                    className="img-fluid"
                    alt="Star Icon"
                  />
                  Best For You <span className="badge">24</span>
                </h2>
                <p>Some of our hand-picked properties for you.</p>
              </div>
              <div className="common-btn">
                <a href="">Browse more properties</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-gap">
          {properties.map((property) => (
            <div className="col-lg-4 col-md-6" key={property.id}>
              <div className="plots-card">
                <div className="image">
                  <img
                    src={imageLoader(property.image)} // Use the common image import function for property images
                    className="img-fluid"
                    alt={property.title}
                  />
                </div>
                <div className="context">
                  <div className="badge">
                    <img
                      src={imageLoader("icons/stars.svg")}
                      alt="Stars Badge"
                    />
                    <span>{property.badge}</span>
                  </div>
                  <div className="price-likes">
                    <div className="price">
                      <h4>
                        <span className="cost">{property.price}</span>
                        <span className="month">{property.month}</span>
                      </h4>
                    </div>
                    <div className="likes">
                      <img
                        src={imageLoader("icons/heart.svg")} // Use the common image import function
                        className="img-fluid"
                        alt="Heart Icon"
                      />
                    </div>
                  </div>
                  <h3>{property.title}</h3>
                  <p>{property.address}</p>
                  <div className="numbers-flex">
                    <ul>
                      <li>
                        <img
                          src={imageLoader("icons/bed.svg")}
                          alt="Bed Icon"
                        />
                        {property.beds} Beds
                      </li>
                      <li>
                        <img
                          src={imageLoader("icons/tub.svg")}
                          alt="Tub Icon"
                        />
                        {property.bathrooms} Bathrooms
                      </li>
                      <li>
                        <img
                          src={imageLoader("icons/square.svg")}
                          alt="Square Icon"
                        />
                        {property.area}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomePlots;
