import React from "react";
import Header from "../components/common/CommonHeader.js";
import SearchLocation from "../components/Location/SearchLocation.js";

const LocationPage = () => (
  <div>
    <Header showMapBanner={false} />
    <SearchLocation />
  </div>
);

export default LocationPage;
