// SignIn.js
import React, { useState } from "react";

const SignIn = ({ onSignIn, toggleForgotPassword, toggleRegister }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleSignIn = (e) => {
    e.preventDefault();
    // Call the onSignIn function passed from the parent to handle sign-in logic
    onSignIn({ email, password, rememberMe });
  };

  return (
    <div class="auth-data">
      <form onSubmit={handleSignIn}>
        <div className="section-title">
          <div className="heading">
            <h2>Sign In</h2>
            <p>
              Don’t have an account?{" "}
              <a href="#" onClick={toggleRegister}>
                Create now
              </a>
            </p>
          </div>
        </div>

        <div className="form-group">
          <label>E-mail</label>
          <input
            type="email"
            className="form-control"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div class="row">
          <div className="col-lg-6 col-md-6 col-xs-12">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="rememberMe"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label className="form-check-label" htmlFor="rememberMe">
                Remember Me
              </label>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xs-12">
            <div className="forgot-password">
              <a href="#" onClick={toggleForgotPassword}>
                Forgot Password?
              </a>
            </div>
          </div>
        </div>
        <div class="button-holder">
          <button type="submit" className="btn btn-primary">
            Sign In
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
