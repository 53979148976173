import React from "react";

const Map = ({ showMapBanner }) => {
  return (
    <>
      <section className="map">
        <div className="map-background">
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.235796162362!2d-122.41941518468178!3d37.77492977975809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808581d947a1d015%3A0x60ca47c9ef58c3c!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1638961838726!5m2!1sen!2s"
            allowFullScreen
            title="Google Map of San Francisco"
          ></iframe>
        </div>
      </section>
      {showMapBanner && (
        <section className="home-map-banner custom-spacing">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-content-left">
                  <div className="form-group">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search places in map with AI"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Map;
