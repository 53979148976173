import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const Newsletter = () => {
  return (
    <section className="home-newsletter custom-spacing">
      <Container>
        <Row>
          <Col lg={12}>
            <h3>No Spam Promise</h3>
            <h2>Are you a landlord?</h2>
            <p>
              Discover ways to increase your home's value and get listed. No
              Spam.
            </p>
            <Form className="form-group">
              <Form.Control
                type="text"
                placeholder="Enter your email address"
              />
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
            <span>
              Join <b>10,000+</b> other landlords in our estatery community.
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Newsletter;
